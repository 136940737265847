<template>
    <div @click=" $emit('selectBuilder', item.id ) ">
        <button class="btn btn-light w-100 mb-1" :class=" leftMenu ? '' : 'd-none' ">
            <span :class=" selected ? 'text-info': '' ">
                <i class="fa fa-list-alt mr-2"></i>
                <span v-text="item.type"></span>
            </span>
        </button>
        <div class="animated fadeIn" v-if="selected" :class=" leftMenu ? 'left-menu' : 'right-menu' ">
            <p>Panel menu</p>
            <pre> {{leftMenu}} </pre>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            item: {
                type: Object,
            },
            selected: {
                type: Boolean,
            },
            leftMenu: {
                type: Boolean,
                default: false,
            },
        }
    }
</script>

<style lang="scss">
    .left-menu {
        display: none !important;
        @media only screen and (min-width: 767px) {
            display: block;
        }
    }

    // .right-menu {
        // @media only screen and (max-width: 767px) {
        //     display: none !important;
        // }
    // }
</style>